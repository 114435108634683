<template>
  <div class="container mx-auto block relative z-50">
    <div class="flex flex-wrap">
      <div class="flex-1 w-auto">
        <div class="flex flex-row items-center w-full pt-4 px-3">
          <div class="flex-1">
            <a target="_blank" href="https://nextearth.io"
              ><img
                src="@/assets/images/header/logo.svg"
                class="w-28 lg:w-32 mt-4 lg:mt-[23px] mx-4 lg:mx-4"
                alt="logo"
            /></a>
          </div>
          <div class="flex-none">
            <a
              v-if="state.status"
              @click="disconnectUser"
              class="btn btn-primary mt-4 lg:mt-[23px]"
              href="#"
              id="show-modal"
              role="button"
              >Disconnect Wallet</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import connect from "@/helpers/index";
export default {
  name: "Navigation",
  setup: () => {
    const { disconnectWallet, state } = connect();

    const disconnectUser = async () => {
      await disconnectWallet();
    };

    return {
      disconnectUser,
      state,
    };
  },
};
</script>
