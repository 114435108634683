<template>
  <div>
    <div id="navigation-logo" class="relative">
      <div id="logo-bg"></div>
      <a target="_blank" href="https://nextearth.io"
        ><img
          src="@/assets/images/header/logo.svg"
          class="max-w-full h-auto"
          alt="logo"
      /></a>
    </div>
    <div id="navigation" class="flex relative z-10 text-white py-5">
      <div class="flex-1 grow text-white">
        <h1 class="text-display-1 font-bold uppercase">My portfolio</h1>
      </div>
      <div class="flex-none shrink">
        <div class="flex">
          <!-- <div class="flex-none nav-item">
            <a target="_blank" href="https://nextearth.io"
              ><img
                src="@/assets/images/icons/notif-icon.svg"
                class="max-w-full h-auto"
                alt="logo"
            /></a>
          </div> -->
          <!-- <div class="flex-none nav-item">
            <img
              src="https://picsum.photos/64"
              width="32px"
              height="auto"
              class="max-w-full h-auto rounded-full"
              alt="logo"
            />
          </div> -->
          <div
            class="flex-none nav-item"
            v-if="state.address.toLowerCase() == ref_wallet.toLowerCase()"
          >
            <button class="z-10 btn btn-link" @click="downloadReport">
              <IconDownload class="h-6 w-6 text-white" />
              <span class="ml-4">Download Report </span>
            </button>
          </div>
          <div class="flex-none nav-item">
            <button class="z-10 btn btn-link" @click="disconnect">
              <IconLogout class="h-6 w-6 text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-white">
      <h2 class="text-3xl uppercase">Dashboard</h2>
    </div>
    <PassModal :show="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import connect from "@/helpers/index";
import IconLogout from "@/components/icons/IconLogout.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import PassModal from "@/modals/PassModal.vue";
export default {
  name: "DashboardHeader",
  components: {
    IconLogout,
    IconDownload,
    PassModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    disconnect: function () {
      const code = this.state.inviteCode;
      this.disconnectUser();
      this.$router.replace({
        path: "/" + code,
      });
    },
    downloadReport: function () {
      this.showModal = true;
    },
  },
  watch: {
    step: function (val) {
      if (val != 6) {
        const code = this.state.inviteCode;
        this.$router.replace({
          path: "/" + code,
        });
      }
    },
  },
  computed: {
    ref_wallet: function () {
      return process.env.VUE_APP_ADMIN_WALLET;
    },
    step: function () {
      return this.state.step;
    },
  },
  setup: () => {
    const { connectWalletConnect, disconnectWallet, state } = connect();
    const connectUserWallet = async () => {
      await connectWalletConnect();
    };

    const disconnectUser = async () => {
      await disconnectWallet();
    };

    return {
      connectUserWallet,
      disconnectUser,
      state,
    };
  },
  mounted() {
    let ethereum = window.ethereum;
    if (typeof ethereum !== "undefined") {
      this.hasEthProvider = true;
      ethereum.on("chainChanged", (chainId) => {
        this.state.chainId = chainId;
        this.disconnect();
      });
    }
  },
};
</script>
