<template>
  <GradientBox>
    <div class="p-6 text-white uppercase">
      <h3>Your portfolio</h3>

      <div>
        <div class="flex items-center mt-24">
          <div class="flex-1 uppercase">
            <h4 class="opacity-50">BONUS</h4>
          </div>
          <div
            class="flex-none shrink"
            v-if="true"
            v-tooltip:top.tooltip="
              'Bonus indicates the percentages of your commitment received after the locking period is ended expressed in NXTT'
            "
          >
            <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer" />
          </div>
        </div>
        <div class="flex items-center mt-2">
          <div class="flex-1 uppercase">
            <h2>%</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ cashout_discount }}</h2>
          </div>
        </div>
      </div>

      <div>
        <div class="flex items-center mt-24">
          <div class="flex-1 uppercase">
            <h4 class="opacity-50">Your portfolio actual value</h4>
          </div>
          <div
            class="flex-none shrink"
            v-if="true"
            v-tooltip:top.tooltip="
              'The value of your portfolio calculated by the actual market price of NXTT'
            "
          >
            <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer" />
          </div>
        </div>
        <div class="flex-column md:flex mt-2">
          <div class="flex-1 uppercase">
            <h2>NXTT</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ actual_nxxt }}</h2>
          </div>
        </div>
        <div class="flex-column md:flex mt-2">
          <div class="flex-1 uppercase">
            <h2>$</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ actual_usd }}</h2>
          </div>
        </div>
      </div>

      <div>
        <div class="flex items-center mt-24">
          <div class="flex-1 uppercase">
            <h4 class="opacity-50">at the end of your lock period</h4>
          </div>
          <div
            class="flex-none shrink"
            v-if="true"
            v-tooltip:top.tooltip="
              'The value of your portfolio after your locking period is over. This value includes your guaranted ROI, the initially locked amount of NXTT and your loss compensation.'
            "
          >
            <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer" />
          </div>
        </div>
        <div class="flex-column md:flex mt-2">
          <div class="flex-1 uppercase">
            <h2>NXTT</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ final_nxxt }}</h2>
          </div>
        </div>
        <div class="flex-column md:flex mt-2">
          <div class="flex-1 uppercase">
            <h2>$</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ final_usd }}</h2>
          </div>
        </div>
      </div>

      <div>
        <div class="flex items-center mt-24">
          <div class="flex-1 uppercase">
            <h4 class="opacity-50">claim now value</h4>
          </div>
          <div
            class="flex-none shrink"
            v-if="true"
            v-tooltip:top.tooltip="
              'The value of your portfolio after deducting the penalty percentages (termination of the locking period)'
            "
          >
            <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer" />
          </div>
        </div>
        <div class="flex items-center mt-2">
          <div class="flex-1 uppercase">
            <h2>NXTT</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ claim_nxxt }}</h2>
          </div>
        </div>
        <div class="flex items-center mt-2">
          <div class="flex-1 uppercase">
            <h2>$</h2>
          </div>
          <div class="flex-none uppercase shrink">
            <h2>{{ claim_usd }}</h2>
          </div>
        </div>
      </div>

      <div class="text-center mt-24">
        <button
          @click="$emit('click')"
          class="btn btn-primary relative z-10 mx-auto w-full lg:w-auto"
        >
          ASK FOR CLAIM
        </button>
      </div>
    </div>
  </GradientBox>
</template>

<script>
import GradientBox from "@/components/base/GradientBox.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import Tooltip from "@/components/base/Tooltip.vue";
export default {
  name: "DashboardPortfolio",
  components: {
    GradientBox,
    IconInfo,
    Tooltip,
  },
  props: {
    cashout_discount: {
      required: true,
      type: [Number, String],
    },
    actual_nxxt: {
      required: true,
      type: [Number, String],
    },
    actual_usd: {
      required: true,
      type: [Number, String],
    },
    final_nxxt: {
      required: true,
      type: [Number, String],
    },
    final_usd: {
      required: true,
      type: [Number, String],
    },
    claim_nxxt: {
      required: true,
      type: [Number, String],
    },
    claim_usd: {
      required: true,
      type: [Number, String],
    },
  },
};
</script>
