<template>
  <div class="relative z-0 text-white pb-[40px]">
    
    <!-- <div class="my-10">
      <div class="container mx-auto px-6 flex justify-between">
        <div></div>
        <NeedHelp/>
      </div>
    </div> -->
    
    <div class="container mx-auto pb-[40px] relative">
      <div class="border-t-[1px] border-white/20 absolute top.0 left-6 right-6 h-1"></div>
    </div>
    <div class="container mx-auto sm:px-3 px-3">
      <div class="flex flex-wrap px-3">
        <div class="w-full lg:w-1/3">
          <div class="w-[129px]">
            <a target="_blank" href="https://nextearth.io">
              <img
                src="../assets/images/header/logo.svg"
                class="max-w-full h-auto"
                alt="logo"
              />
            </a>
          </div>
          <p class="mt-4 mb-0">
            NFT based, transparent digital land purchasing platform without a
            middle-man. Instantly buying selling land, directly from your
            wallet.
          </p>
          
          <!-- SOCIAL ICONS -->
          <div
            class="flex flex-row items-center justify-between mt-5 mb-10"
          >
            <a 
              v-for="(social, idx) in socials"
              :key="`icon${idx}`"
              :href="social.path" target="_blank"> 
              <component
                v-if="social.icon"
                :is="iconComponent(social.icon)"
                class="h-7 w-7 text-white bg-transparent hover:shadow-pink hover:text-ne-pink-200 hover:opacity-90 transition-all duration-500" 
              />
            </a>
          </div>

          <!-- COMPANY IN DESKTOP -->
          <p class="hidden lg:block mb-0">
            <span>&copy; Nextearth</span>
            <span>&nbsp;</span>
            <span>&#x2022</span>
            <span>&nbsp;</span>
            <span>2022</span>
            <span>&nbsp;</span>
          </p>
        </div>
        
        <!-- MENU -->
        <div 
          v-for="(item,idx) in items"
          :key="`item${idx}`"
          class="w-1/2 relative lg:flex-grow lg:flex-1 ml-auto"
        >
          <div class="float-none lg:float-right">
            <h3 
              v-if="item.name"
              class="mb-4 text-base font-bold"
            >
              {{item.name}}
            </h3>
            <ul 
              v-if="item.sub.length"
              class="flex flex-wrap list-none pl-0 mb-0 flex-col"
            >
              <li 
                v-for="(subitem,idx) in item.sub"
                :key="`subitem${idx}`"
                class="mb-4 text-sm"
              >
                <a
                  :target="subitem.target_blank ? '_blank' : ''"
                  :href="subitem.path"
                  >{{subitem.name}}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <!-- COMPANY IN MOBILE -->
      <div class="flex flex-wrap lg:hidden px-3 mt-6">
        <div class="w-full">
          <p class="mb-0">
            <span>&copy; Nextearth</span>
            <span>&nbsp;</span>
            <span>&#x2022</span>
            <span>&nbsp;</span>
            <span>2022</span>
            <span>&nbsp;</span>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue"
import NeedHelp from "@/components/base/NeedHelp.vue"
export default {
  name: "FooterSec",
  components: {
    NeedHelp
  },
  data(){
    return {
      socials: [
        {
          icon: 'IconMedium',
          path: 'https://medium.com/@nextearth',
        },
        {
          icon: 'IconTwitter',
          path: 'https://twitter.com/nextearth_',
        },
        {
          icon: 'IconTelegram',
          path: 'https://t.me/nextearth_chat',
        },
        {
          icon: 'IconFacebook',
          path: 'https://www.facebook.com/nextearth.io/',
        },
        {
          icon: 'IconReddit',
          path: 'https://www.reddit.com/r/NextEarth/',
        },
        {
          icon: 'IconYouTube',
          path: 'https://www.youtube.com/c/NextEarth_',
        }
      ],
      items: [
        {
          name: 'Technology',
          sub: [
            {
              name: 'Marketplace',
              path: 'https://app.nextearth.io/marketplace',
              target_blank: true
            },
            {
              name: 'Buy Land',
              path: 'https://app.nextearth.io/buy-land',
              target_blank: true
            },
            {
              name: 'Whitepaper',
              path: 'https://whitepaper.nextearth.io/',
              target_blank: true
            },
            {
              name: 'NXTT',
              path: 'https://nextearth.io/#nxtt',
              target_blank: true
            }
          ],
        },
        {
          name: 'Account',
          sub: [
            {
              name: 'Login/Sign up',
              path: 'https://app.nextearth.io/auth/login',
              target_blank: true
            },
            {
              name: 'Profile',
              path: 'ttps://app.nextearth.io/auth/login',
              target_blank: true
            },
            {
              name: 'Settings',
              path: 'https://app.nextearth.io/auth/login',
              target_blank: true
            }
          ],
        },
        {
          name: 'Contact us',
          sub: [
            {
              name: 'Contact our Support',
              path: 'mailto:support@nextearth.io',
              target_blank: false
            },
            {
              name: 'Inquiries',
              path: 'mailto:inquiries@nextearth.io',
              target_blank: false
            },
            {
              name: 'Press Kit',
              path: 'https://whitepaper.nextearth.io/references/official-links',
              target_blank: true
            }
          ],
        },
        {
          name: 'Company',
          sub: [
            {
              name: 'Terms and cons',
              path: 'https://nextearth.io/terms-and-cons',
              target_blank: true
            },
            {
              name: 'Privacy Policy',
              path: 'https://nextearth.io/privacy-policy',
              target_blank: true
            },
            {
              name: 'Cookie Settings',
              path: 'https://nextearth.io/cookie-settings',
              target_blank: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    iconComponent (icon) {
      return defineAsyncComponent(() => import(`@/components/icons/${icon}.vue`))
    }
  }
}
</script>
