<template>  
    <svg viewBox="0 0 24 25" class="shrink-0 fill-current" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 11.0154C12.5523 11.0154 13 11.4631 13 12.0154V16.0154C13 16.5677 12.5523 17.0154 12 17.0154C11.4477 17.0154 11 16.5677 11 16.0154V12.0154C11 11.4631 11.4477 11.0154 12 11.0154Z"/>
      <path d="M13 8.01538C13 7.4631 12.5523 7.01538 12 7.01538C11.4477 7.01538 11 7.4631 11 8.01538V8.03538C11 8.58767 11.4477 9.03538 12 9.03538C12.5523 9.03538 13 8.58767 13 8.03538V8.01538Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12.0154C1 5.94025 5.92487 1.01538 12 1.01538C18.0751 1.01538 23 5.94024 23 12.0154C23 18.0905 18.0751 23.0154 12 23.0154C5.92486 23.0154 1 18.0905 1 12.0154ZM12 3.01538C7.02943 3.01538 3 7.04482 3 12.0154C3 16.9859 7.02944 21.0154 12 21.0154C16.9705 21.0154 21 16.9859 21 12.0154C21 7.04482 16.9705 3.01538 12 3.01538Z"/>
    </svg>
</template>

<script>
export default {
  name: 'IconInfo'
}
</script>