<template>
  <div>
    <modal :show="showModal" @close="showModal = false">
      <template #header>
        <h1 class="modal-title p-6 text-white">{{ title }}</h1>
      </template>
      <template #body>
        <div
          v-if="img"
          class="w-full flex justify-center mb-6">
          <div class="bg-ne-black bg-pink-linear-gradient p-[1px] rounded-full shadow">
            <div class="bg-ne-black rounded-full">
              <img 
                src="@/assets/images/portrait/zsolt.png" alt="zsolt"
                class="rounded-full w-44"
              >
            </div>
          </div>
        </div>
        <p>
          {{ desc }}
        </p>
      </template>
      <template #footer>
        <div class="px-6 pb-6 block">
          <button
            class="btn pinkish text-uppercase w-full"
            @click="$emit('close')"
          >
            CLOSE
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/modals/BasicModal.vue";
export default {
  name: "infoModal",
  components: {
    Modal,
  },
  props: ["title", "desc", "show", "img"],
  data() {
    return {
      showModal: this.show,
    };
  },
  watch: {
    show: function () {
      this.showModal = this.show;
    },
  },
};
</script>
