<template>
  <div class="relative container mx-auto sm:px-3 px-3">
    <div class="flex flex-col lg:flex-row pt-0 lg:pt-12 pb-4">
      <InfoBox
        v-for="(step, idx) in steps"
        :key="`step${idx}`"
        :icon="step.icon"
        :title="step.title"
        :content="step.content"
      />
    </div>
  </div>
</template>

<script>
import InfoBox from "@/components/base/InfoBox.vue";
export default {
  name: "InfoPanelSec",
  components: {
    InfoBox,
  },
  data() {
    return {
      steps: [
        {
          icon: "IconReward",
          title: "Bonus",
          content: `
            <ul>
               <li class="mb-2">&#x2022 10% bonus up on your purchase > $20.000</li>
               <li class="mb-2">&#x2022 12.5% bonus up on your purchase > $50.000</li>
               <li>&#x2022 15% bonus up on your purchase > $100.000</li>
            </li>
          `,
        },
        {
          icon: "IconVesting",
          title: "Vesting",
          content: `
            <ul>
               <li class="mb-2">&#x2022 30% penalty between 0-6 months</li>
               <li class="mb-2">&#x2022 20% penalty between 6-12 months</li>
               <li>&#x2022 no penalty after 12 months</li>
            </li>
          `,
        },
        {
          icon: "IconGuarantee",
          title: "Highlights",
          content: `
            <ul>
               <li class="mb-2">&#x2022 purchases from $200</li>
               <li class="mb-2">&#x2022 impermanent loss compensation</li>
               <li>&#x2022 Token dashboard</li>
            </li>
          `,
        },
      ],
    };
  },
};
</script>
