<template>
    <GradientBox>
        <div class="flex items-center ">
          <div class="flex-none shrink p-4" >
              <IconInfoSquared class="h-7 w-7 text-[#C99100]" />
          </div>
          <div class="flex-1 fill p-4">
            <p class="text-white mb-0 opacity-50">The indicated data is informative, the total value of the portfolio is determined by the current and future market value of NXTT. For more information, contact your personal NXTT advisor</p>
        </div>
        </div>
      </GradientBox>
</template>

<script>
import GradientBox from '@/components/base/GradientBox.vue'
import IconInfoSquared from '@/components/icons/IconInfoSquared.vue'
export default {
    name: 'DashboardInfo',
    components: {
        GradientBox,
        IconInfoSquared
    }
}
</script>