<template>
  <div>
    <section class="my-10 lg:py-12 lg:my-12 mx-1 lg:mx-2">
      <div class="container mx-auto px-3">
        <div class="flex flex-wrap justify-center">
          <div class="w-full">
            <h1 class="text-4xl font-bold uppercase ml-2">Disclaimer</h1>
            <div class="flex flex-col lg:flex-row mt-2 lg:mt-10">
              <div
                v-for="(disclamer, idx) in disclamers"
                :key="`disclamer${idx}`"
                class="relative flex-grow max-w-full flex-1 px-3 mt-6 lg:mt-0"
              >
                <h6 class="mb-1 text-white uppercase text-lg font-bold">
                  {{ disclamer.title }}
                </h6>
                <p class="text-white text-lg">{{ disclamer.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DisclaimerSec",
  data() {
    return {
      disclamers: [
        {
          title: "NO INVESTMENT",
          content:
            "NXTT is a utility token which may be used exclusively on the Next Earth platform in a late stage of the project. NXTT is not an investment product or regulated security, which means that we do not guarantee and do not promise any profits and/or market value increase and/or future market value regarding the NXTT tokens. NXTT may be purchased at the own risk of the purchaser.",
        },
        {
          title: "Excluded markets",
          content:
            "The Issuer expressly excludes the residents of the following countries from the OTC sales: United States of America, People’s Republic of China, Russia, Hong Kong, Indi and all sanctioned OFAC countries (DR Congo, Iran, Myanmar, Sudan, Iraq, Ivory Coast, North Korea, Syria, Zimbabwe, Cuba, Belarus, Liberia).",
        },
        {
          title: "NO WARRANTY",
          content:
            "We make every possible efforts to develop Next Earth Project and increase its value and utility, however, we provide no warranty in connection with the market value, faultless and/or utility of the NXTT tokens.",
        },
      ],
    };
  },
};
</script>
