<template>
  <div>
    <a
      class="btn btn-primary text-lg"
      href="#"
      id="show-modal"
      @click="showModal = true"
      role="button"
      >Login with Wallet</a
    >
    <modal :show="showModal" @close="showModal = false">
      <template #header>
        <h1 class="modal-title p-6 text-white">Connect your wallet</h1>
      </template>
      <template #body>
        <button
          type="button"
          class="connect-wallet-button btn pinkish text-uppercase w-full"
          @click="startWalletConnect"
        >
          <svg
            class="mr-3"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 15V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H16C17.1 0 18 0.9 18 2V3H9C7.89 3 7 3.9 7 5V13C7 14.1 7.89 15 9 15H18ZM9 13H19V5H9V13ZM13 10.5C12.17 10.5 11.5 9.83 11.5 9C11.5 8.17 12.17 7.5 13 7.5C13.83 7.5 14.5 8.17 14.5 9C14.5 9.83 13.83 10.5 13 10.5Z"
              fill="white"
            ></path>
          </svg>
          Wallet Connect
        </button>

        <button
          v-if="hasEthProvider"
          type="button"
          class="connect-wallet-button btn pinkish text-uppercase w-full mt-4"
          @click="startMetaMaskConnect"
        >
          <svg
            class="mr-3"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 15V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H16C17.1 0 18 0.9 18 2V3H9C7.89 3 7 3.9 7 5V13C7 14.1 7.89 15 9 15H18ZM9 13H19V5H9V13ZM13 10.5C12.17 10.5 11.5 9.83 11.5 9C11.5 8.17 12.17 7.5 13 7.5C13.83 7.5 14.5 8.17 14.5 9C14.5 9.83 13.83 10.5 13 10.5Z"
              fill="white"
            ></path>
          </svg>
          Metamask
        </button>
      </template>
      <template #footer></template>
    </modal>
  </div>
</template>

<script>
import connect from "@/helpers/index";
import Modal from "@/modals/BasicModal.vue";
export default {
  name: "ConnectValletBtn",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      hasEthProvider: false,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    startWalletConnect: function () {
      this.connectUserWallet();
      this.showModal = false;
    },
    startMetaMaskConnect: async function () {
      let ethereum = window.ethereum;
      if (ethereum) {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        ethereum.on("accountsChanged", (accounts) => {
          this.disconnectUser();
        });

        ethereum.on("chainChanged", (chainId) => {
          this.disconnectUser();
        });

        this.state.walletType = "MetaMask";
        this.state.status = true;
        this.state.address = accounts[0];
        this.state.chainId = await ethereum.request({ method: "eth_chainId" });
      }
      this.showModal = false;
    },
  },
  setup: () => {
    const { connectWalletConnect, disconnectWallet, state } = connect();
    const connectUserWallet = async () => {
      await connectWalletConnect();
    };

    const disconnectUser = async () => {
      await disconnectWallet();
    };

    return {
      connectUserWallet,
      disconnectUser,
      state,
    };
  },
  mounted() {
    let ethereum = window.ethereum;
    if (typeof ethereum !== "undefined") {
      this.hasEthProvider = true;

      ethereum.on("accountsChanged", (accounts) => {
        this.disconnectUser();
      });

      ethereum.on("chainChanged", (chainId) => {
        this.disconnectUser();
      });
    }
  },
};
</script>
