<template>
  <basic-layout>
    <MetaverseWrapper />
    <Header v-show="state.address == ''" />
    <div v-show="state.address != ''">
      <BuyNxtt />
    </div>
    <div v-show="state.address == ''">
      <InfoPanel />
      <Disclaimer />
    </div>
  </basic-layout>
</template>

<script>
import { useRoute } from "vue-router";
import MetaverseWrapper from "@/components/base/MetaverseWrapper.vue";
import Header from "@/components/Header.vue";
import Disclaimer from "../components/Disclaimer.vue";
import BasicLayout from "./../layouts/BasicLayout.vue";
import InfoPanel from "../components/InfoPanel.vue";
import BuyNxtt from "../components/BuyNxtt.vue";
import connect from "../helpers/index";
export default {
  name: "HomePage",
  components: {
    MetaverseWrapper,
    Header,
    BasicLayout,
    InfoPanel,
    BuyNxtt,
    Disclaimer,
  },
  setup: () => {
    const { state } = connect();
    return {
      state,
    };
  },
  mounted() {
    const route = useRoute();
    const invite_code = route.params.invite_code;
    this.checkCode(invite_code);
  },
  methods: {
    checkCode: async function (invite_code) {
      if (
        invite_code != null &&
        invite_code != "" &&
        invite_code != undefined
      ) {
        const validation = await this.validateCode(invite_code);
        if (validation) {
          this.state.inviteCode = invite_code;
          return;
        } else {
          this.redirectUser();
        }
      } else {
        this.redirectUser();
      }
    },
    redirectUser: function () {
      window.location.href = "https://nextearth.io";
      return;
    },
    validateCode: async function (code) {
      const response = await this.axios
        .get(process.env.VUE_APP_BACKEND_API + "api/v1/checkCode/" + code)
        .catch((e) => {
          return false;
        });
      return response.data.validation;
    },
  },
};
</script>
<style lang=""></style>
