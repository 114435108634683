<template>  
    <svg viewBox="0 0 24 25" class="shrink-0 fill-current"  xmlns="http://www.w3.org/2000/svg">
      <path d="M17 7.01538L15.59 8.42538L18.17 11.0154H8V13.0154H18.17L15.59 15.5954L17 17.0154L22 12.0154L17 7.01538ZM4 5.01538H12V3.01538H4C2.9 3.01538 2 3.91538 2 5.01538V19.0154C2 20.1154 2.9 21.0154 4 21.0154H12V19.0154H4V5.01538Z"/>
    </svg>
</template>

<script>
export default {
  name: 'IconLogout'
}
</script>