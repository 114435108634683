<template>
    <div class="relative flex-grow max-w-full flex-1 px-3 mt-6 lg:mt-0">   
        <GradientBox class="p-8" >
            <div class="w-full flex justify-end">
                <component
                v-if="icon"
                :is="iconComponent"
                class="h-[2.875rem] w-[2.875rem] text-white/40" 
                />
            </div>
            <h3 
                v-if="title"
                class="text-3xl text-white font-bold uppercase mb-6"
            >
                {{title}}
            </h3>
            <div 
                v-if="content" 
                v-html="content">
            </div>    
        </GradientBox>
    </div>    
</template>

<script>
import {defineAsyncComponent} from "vue"
import GradientBox from "@/components/base/GradientBox.vue"
export default {
    components: {
        GradientBox
    },
    props: {
        icon: {
            required: false,
            type: String,
            default: null
        },
        title: {
            required: false,
            type: String,
            default: null
        },
        content: {
            required: false,
            type: String,
            default: null
        }
    },
    computed: {
        iconComponent () {
            return defineAsyncComponent(() => import(`@/components/icons/${this.icon}.vue`))
        }
    }
}
</script>