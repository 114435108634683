<template>  
    <svg width="32" height="32" viewBox="0 0 32 32" class="shrink-0 fill-current" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16"/>
      <path d="M24.4 7.61328C24.1509 7.3636 23.8127 7.22329 23.46 7.22329C23.1073 7.22329 22.7691 7.3636 22.52 7.61328L16 14.1199L9.47996 7.59994C9.23085 7.35027 8.89265 7.20996 8.53996 7.20996C8.18727 7.20996 7.84907 7.35027 7.59996 7.59994C7.07996 8.11994 7.07996 8.95994 7.59996 9.47994L14.12 15.9999L7.59996 22.5199C7.07996 23.0399 7.07996 23.8799 7.59996 24.3999C8.11996 24.9199 8.95996 24.9199 9.47996 24.3999L16 17.8799L22.52 24.3999C23.04 24.9199 23.88 24.9199 24.4 24.3999C24.92 23.8799 24.92 23.0399 24.4 22.5199L17.88 15.9999L24.4 9.47994C24.9066 8.97327 24.9066 8.11994 24.4 7.61328Z" fill="#000000"/>
    </svg>
</template>

<script>
export default {
  name: 'IconClose',
}
</script>