import { provider } from "../providers/provider";
import connect from "./index";
const disconnectWallet = async () => {
  const { state } = connect();

  if (provider.accounts.length != 0) {
    await provider.disconnect();
  }
  state.walletType = "";
  state.address = "";
  state.wallet = "";
  state.chainId = "";
  state.name = "";
  state.email = "";
  state.sign = "";
  state.status = false;
  state.step = 1;
  state.hash = "";
  state.trx_amounts = null;
  state.trxStatus = null;
  state.usd = 0;
  state.amount = 0;
  state.trx_receipt = null;
  state.trx_date = "";
};
export default disconnectWallet;
