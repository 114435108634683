<template>
    <div class="otc-custom-box">
        <slot></slot>
    </div>
</template>

<style lang="scss">
.otc-custom-box {
    /* bgMorph2 */
    position: relative;
    background: #050011;
    /* bgMorph2 */
    box-shadow: -10px -10px 30px #201636, 10px 10px 40px #000001;
    padding: 13px;
    border-radius: 16px;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 16px;
      padding: 1px;
      opacity: 0.9;
      background: linear-gradient(
        45deg,
        rgba(42, 0, 111, 1),
        rgba(231, 231, 231, 0.15)
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
}
</style>