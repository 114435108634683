<template lang="">
  <div>
    <div class="bg"></div>
    <div class="chart-token p-4">
      <div class="moon-box d-block d-md-none d-lg-block">
        <img src="" width="100%" height="auto" alt="" />
      </div>
      <div class="token-symbol flex items-center">
        <div class="inline-block mr-4">
          <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.1395 5.87609C26.3256 -1.93786 13.6744 -1.93786 5.86046 5.87609C-1.95349 13.69 -1.95349 26.3412 5.86046 34.1552C13.6744 41.9691 26.3256 41.9691 34.1395 34.1552C41.9535 26.3412 41.9535 13.69 34.1395 5.87609ZM31.7475 31.9758C28.5581 34.9525 20.7973 32.1352 14.3654 25.7033C7.93355 19.2714 5.11628 11.2449 8.19934 8.1618C11.3355 4.97244 15.588 3.27144 20.0532 3.27144C24.5183 3.27144 28.7176 5.02559 31.907 8.1618C38.392 14.6469 38.4452 25.2249 31.9601 31.7631L31.8538 31.9226C31.8007 31.9226 31.8007 31.9226 31.7475 31.9758Z"
              fill="white"
            />
          </svg>
        <div></div>
        </div>
        <div class="inline-block">
          <h5 class="mb-0 color-white">NXTT/MATIC</h5>
          <small class="color-white">(90d)</small>
        </div>
      </div>
      <div class="chart-content pb-3 pt-4 pt-lg-0">
        <canvas id="nxtt-chart" height="200px"></canvas>
      </div>

      <div class="grid grid-cols-2 md:grid-cols-4 text-white">
        <div class="text-center mt-3">
          <h5 class="mb-0 color-white">{{ current_volume }}</h5>
          <small class="color-white opacity-50" :class="percent_class">{{
            percent_volume
          }}</small>
        </div>
        <div class="text-center mt-3">
          <h5 class="mb-0 color-white">8803</h5>
          <small class="color-white opacity-50">Holder</small>
        </div>
        <div class="text-center mt-3">
          <h5 class="mb-0 color-white">01.27.2022</h5>
          <small class="color-white opacity-50">Launch date</small>
        </div>
        <div class="text-center mt-3">
          <h5 class="mb-0 color-white">
            <a
              href="https://www.coingecko.com/en/coins/next-earth"
              class="color-white clear-a"
              target="_blank"
              >CoinGecko</a
            >
          </h5>
          <small class="color-white opacity-50 block">Source</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NxttChart",
  data() {
    return {
      ctx: null,
      data: [],
      current_volume: null,
      percent_volume: null,
      percent_class: "",
    };
  },
  watch: {
    data: function (val) {
      this.renderChart(this.data);
    },
  },
  mounted() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI: async function () {
      this.ctx = document.getElementById("nxtt-chart").getContext("2d");

      const req = await fetch(
        "https://api.coingecko.com/api/v3/coins/next-earth/market_chart?vs_currency=usd&days=90"
      );
      const response = await req.json();
      let volumes = response.prices;
      var prices = [];
      volumes.forEach(function (item, i) {
        if (i % 10 == 0) {
          prices.push(item[1] * 1000);
        }
      });
      this.data = prices;

      var current = this.data[this.data.length - 1] / 1000;
      var start = 0.0015 * 1000;
      var end = this.data[this.data.length - 1];
      var percent_value = start / 100;
      var value = (end - start) / percent_value;
      this.current_volume = "$" + current.toFixed(6);

      if (value > 100) {
        this.percent_class = "color-green";
        this.percent_volume = "+ " + value.toFixed(2) + "%";
      } else {
        this.percent_class = "color-red";
        this.percent_volume = "- " + value.toFixed(2) + "%";
      }
    },
    renderChart: function (data) {
      // Set up chart height
      this.ctx.height = 300;

      // Setup Stroke gradient
      var gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#2CCE8F");
      gradientStroke.addColorStop(1, "#2CCE8F");

      // Setup Fill gradient
      var gradientFill = this.ctx.createLinearGradient(0, 0, 0, 175);
      gradientFill.addColorStop(0, "rgba(44, 206, 143, 0.23)");
      gradientFill.addColorStop(1, "rgba(44, 206, 143, 0.0)");

      // Init Chart JS obj
      new Chart(this.ctx, {
        type: "line",
        data: {
          labels: data,
          datasets: [
            {
              lineTension: 0,
              label: null,
              skipLabels: 1,
              borderColor: gradientStroke,
              pointBorderColor: gradientStroke,
              pointBackgroundColor: gradientStroke,
              pointHoverBackgroundColor: gradientStroke,
              pointHoverBorderColor: gradientStroke,
              pointBorderWidth: 0,
              pointHoverRadius: 0,
              pointHoverBorderWidth: 1,
              pointRadius: 0,
              fill: true,
              backgroundColor: gradientFill,
              borderWidth: 2,
              data: data,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          scales: {
            yAxes: [
              {
                display: false,
                ticks: {
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "bold",
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  padding: 20,
                },
                gridLines: {
                  drawTicks: false,
                  display: false,
                },
                ticks: {
                  stepSize: 1,
                },
              },
            ],
            xAxes: [
              {
                display: false,
                gridLines: {
                  drawTicks: false,
                  display: false,
                },
                ticks: {
                  padding: 20,
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "bold",
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.chart-token {
  .color-green {
    color: #2cce8f !important;
  }
  .color-red {
    color: #f00 !important;
  }
  position: relative;
  z-index: 9;
  display: block;
  width: 100%;

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 12px;
  .chart-content {
    #nxtt-chart {
      height: 200px !important;
    }
  }
}
</style>
