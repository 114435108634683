<template>
  <Transition name="modal">
    <div v-if="show" class="fixed z-[99989] top-0 left-0 w-full h-full bg-ne-mask transition-opacity duration-300 ease-in-out flex justify-center items-center">
      <div class="table-cell">
        <div class="modal-container relative z-[999] w-[360px] lg:w-[530px] bg-ne-black bg-pink-linear-gradient p-[1px] rounded-xl shadow transition-all ease-in">
          <div class="bg-ne-black p-1 lg:p-6 text-ne-white rounded-xl">
            <div class="modal-header pb-2 pl-2 uppercase font-bold">
              <slot name="header">default header</slot>
            </div>

            <div class="modal-body pb-8 px-8">
              <slot name="body">default body</slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button 
                  class="absolute -top-7 -right-6 cursor-pointer" 
                  @click="$emit('close')"
                >
                  <IconClose class="text-white/80 h-7 w-7"/>
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import IconClose from "@/components/icons/IconClose.vue"
export default {
  components: {
    IconClose
  },
  props: {
    show: Boolean,
  }
}
</script>

<style>
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* #connect-wallet-modal .modal-dialog {
  height: 340px;
  top: 50%;
  margin-top: -170px;
  max-width: 530px;
}

#connect-wallet-modal .modal-header {
  border: none;
  padding: 36px 36px 0px;
}

#connect-wallet-modal .modal-header h5 {
  color: #fff;
  font-size: 36px !important;
  line-height: 38px;
}

#connect-wallet-modal .btn-close {
  margin: 0px 0px 0px 20px;
  opacity: 1;
}

#connect-wallet-modal .modal-content {
  background: linear-gradient(
    180deg,
    rgba(5, 0, 17, 0.6) 0%,
    rgba(5, 0, 17, 0.55) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 3px 15px #050011, 0px 20px 50px rgba(70, 9, 174, 0.75),
    inset 0px 0px 21px rgba(70, 9, 174, 0.5);
  border-radius: 12px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

#connect-wallet-modal .modal-content p {
  color: #fff;
  opacity: 0.5;
  margin: 0px;
}

#connect-wallet-modal .modal-body {
  padding: 12px 36px 36px;
}

#connect-wallet-modal .modal-footer {
  border: none !important;

  padding: 0px 36px 36px;
}
#connect-wallet-modal .modal-footer * {
  margin: 0px;
}
#connect-wallet-modal .modal-footer row div:first-child {
  padding-right: 6px;
}
#connect-wallet-modal .modal-footer row div:last-child {
  padding-left: 6px;
}
#connect-wallet-modal .modal-footer button {
  min-width: 220px;
}

#connect-wallet-modal .modal-footer button svg {
  margin-top: -3px;
  margin-right: 5px;
} */
</style>
