<template>
  <div>
    <modal :show="showModal" @close="showModal = false">
      <template #header>
        <h1 class="modal-title p-6 text-white">Download OTC report!</h1>
      </template>
      <template #body>
        <div class="py-4">
          <label class="ne-form-label">Password:</label>
          <input type="password" v-model="password" class="form-control" />
        </div>
      </template>
      <template #footer>
        <div class="px-6 pb-6 block">
          <a
            class="btn pinkish text-uppercase w-full"
            target="_blank"
            :href="'https://otc.nextearth.io/api/v1/report/' + password || ''"
          >
            DOWNLOAD
          </a>

          <button
            class="btn btn-outline-primary mt-4 mb-2 lg:mb-0 w-full text-center cursor-pointer"
            @click="$emit('close')"
          >
            CANCEL
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/modals/BasicModal.vue";
export default {
  name: "PassModal",
  components: {
    Modal,
  },
  props: ["show"],
  data() {
    return {
      password: null,
      showModal: this.show,
    };
  },
  watch: {
    show: function () {
      this.showModal = this.show;
    },
  },
};
</script>
