<template>
    <a href="https://discord.com/invite/nextearth" target="_blank">
        <GradientBox class="p-8 text-white w-full lg:w-[362px]">
            <div class="flex items-center">
                <IconDiscordFill class="h-[65px] w-[65px] text-white mr-4"/>
                <div>
                    <h2 class="text-2xl font-bold text-white mb-1">Need Help?</h2>
                    <p>If you want to learn more, or ask the team behing Next Earth, join our Discord server</p>
                </div>
            </div>
        </GradientBox>
    </a>
</template>

<script>
import GradientBox from "@/components/base/GradientBox.vue"
import IconDiscordFill from "@/components/icons/IconDiscordFill.vue"
export default {
    components: {
        GradientBox,
        IconDiscordFill
    }
}
</script>