import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import './assets/styles/main.css';
import axios from "axios";
import VueAxios from "vue-axios";
import vTitle from "vuejs-title";
import directives from "./directives/";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const app = createApp(App).use(vTitle).use(VueAxios, axios).use(router);

// register all directives
directives(app);
app.mount("#app");
