<template>
  <div>
    <Navigation />
    <main
      id="main"
      class="relative z-0 flex-1 font-sans text-white bg-fixed bg-no-repeat focus:outline-none bg-ne-black"
    >
      <slot></slot>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue"
import Footer from "@/components/Footer.vue"
export default {
  name: "BasicLayout",
  components: {
    Navigation,
    Footer
  },
}
</script>
<style lang=""></style>
