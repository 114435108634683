<template>
  <div>
    <div class="container mx-auto px-4">
      <DashboardNavigation />
      <main id="main" class="">
        <slot> </slot>
      </main>
    </div>
    <div class="pt-7">
      <Footer />
    </div>
  </div>
</template>

<script>
import DashboardNavigation from "@/components/dashboard/DashboardNavigation.vue";
import Footer from "./../components/Footer.vue";
export default {
  name: "BasicLayout",
  components: {
    DashboardNavigation,
    Footer,
  },
};
</script>
<style lang="scss">
body {
  overflow: visible;
  #app-content {
    overflow: hidden;
  }
}
#navigation-logo {
  overflow: visibile;
  padding-top: 40px;
  a {
    img {
      width: 160px;
      height: 63.02px;
    }
  }
  #logo-bg {
    display: block;
    position: absolute;
    left: -180px;
    margin-top: -160px;
    width: 550px;
    height: 550px;
    padding: 10px;
    background-image: url("@/assets/images/header/circle-bg.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.nav-item {
  margin: 0px 10px 0px;
  a {
    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
