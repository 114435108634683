<template>
    <GradientBox>
        <div class="p-6 text-white" >
            <h4 class="uppercase">Next Earth Metaverse</h4>
            <p class="pt-3">For Your complete experience special Land offers waiting for You in the Next Earth Metaverse. Check them on the link below.</p>
            <!-- ACTION -->
            <div class="text-center mt-24 relative">
                <a href="https://app.nextearth.io" target="_blank" class="block max-w-full w-full btn btn-primary flex just items-center">
                    Next Earth Metaverse
                </a>
            </div>
        </div>
    </GradientBox>
</template>

<script>
import GradientBox from '@/components/base/GradientBox.vue'
export default {
    name: 'DashboardMetaverse',
    components: {
        GradientBox
    }
}
</script>