<template>
  <GradientBox class="p-10">
    <div class="mb-8 z-30 relative">
      <h3 class="text-center text-white uppercase">Time remaining</h3>
    </div>
    <div
      class="relative z-0 mx-auto h-[240px] w-[240px] rounded-full flex center center rotate-[270deg]"
    >
      <div
        class="relative z-0 bg-lock-gradient h-full w-full rounded-full top-0 left-0"
      >
        <svg
          height="240"
          width="240"
          class="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <defs>
            <linearGradient id="pink-gradient" x1="0" y1="1" x2="1" y2="0">
              <stop offset="0" stop-color="#AD00FF" />
              <stop offset="1" stop-color="#FF5665" />
            </linearGradient>
            <linearGradient id="black-gradient" x1="0" y1="1" x2="1" y2="0">
              <stop offset="0" stop-color="#130729" />
              <stop offset="1" stop-color="#050011" />
            </linearGradient>
            <linearGradient id="gray-gradient" x1="0" y1="1" x2="1" y2="0">
              <stop offset="0" stop-color="#2A006F" />
              <stop offset="1" stop-color="#050011" />
            </linearGradient>
            <radialGradient id="bg-gradient">
              <stop offset="1%" stop-color="#16072e" />
              <stop offset="99%" stop-color="#050011" />
            </radialGradient>
          </defs>
          <circle
            cx="120"
            cy="120"
            r="120"
            stroke="url(#gray-gradient)"
            stroke-width="1"
            fill="url(#black-gradient)"
          />
          <circle
            cx="120"
            cy="120"
            r="72"
            stroke="#151416"
            stroke-width="32"
            fill="transparent"
          />
          <circle
            cx="120"
            cy="120"
            r="88"
            stroke="url(#gray-gradient)"
            stroke-width="1"
            fill="transparent"
          />
          <circle
            cx="120"
            cy="120"
            r="56"
            stroke="url(#gray-gradient)"
            stroke-width="1"
            fill="transparent"
          />
          <circle
            cx="120"
            cy="120"
            r="72"
            stroke="url(#pink-gradient)"
            stroke-width="32"
            fill="transparent"
            :stroke-dasharray="drawCircle"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl text-[#9178B2] rotate-[-270deg]"
      >
        {{ percent }}%
      </div>
    </div>
    <div class="pt-6">
      <p class="text-white text-3xl font-bold uppercase mb-4">lock period</p>
      <div class="flex justify-between">
        <div>
          <p class="text-gray-500 text-lg uppercase">Start</p>
          <p class="text-white text-xl">{{ timeConverter(startDate) }}</p>
        </div>
        <div>
          <p class="text-gray-500 text-lg uppercase">End</p>
          <p class="text-white text-xl">{{ timeConverter(endDate) }}</p>
        </div>
      </div>
    </div>
  </GradientBox>
</template>

<script>
import GradientBox from '@/components/base/GradientBox.vue'
export default {
  name: 'DashboardTimeRemaining',
  components: {
    GradientBox,
    GradientBox
},
  props: {
    startDate: {
      required: true,
      type: [Number, String],
    },
    endDate: {
      required: true,
      type: [Number, String],
    },
  },
  data() {
    return {
      percent: null,
    };
  },
  methods: {
    timeConverter(timeStamp) {
      let a = new Date(timeStamp * 1000),
        year = a.getFullYear(),
        month = a.getMonth() + 1,
        date = a.getDate(),
        hour = a.getHours(),
        min = a.getMinutes();
      parseInt(date) < 10 ? (date = `0${date}`) : null;
      parseInt(month) < 10 ? (month = `0${month}`) : null;
      parseInt(hour) < 10 ? (hour = `0${hour}`) : null;
      parseInt(min) < 10 ? (min = `0${min}`) : null;
      return `${date}.${month}.${year} ${hour}:${min}`;
      //parseInt((new Date('2012.08.10').getTime() / 1000).toFixed(0))
    },
    calcPercentage() {
      const recentDate = Math.floor(new Date().getTime() / 1000);
      const fullPeriod = this.endDate - this.startDate;
      const leftPeriod = recentDate - this.startDate;
      return 100 - Math.floor((leftPeriod / fullPeriod) * 100);
    },
  },
  computed: {
    drawCircle() {
      let fakePercent;
      this.percent = this.calcPercentage();
      this.percent > 92 && this.percent < 100
        ? (fakePercent = 92)
        : (fakePercent = this.percent);
      const r = 72;
      const PI = 3.14;
      const circleLine = 2 * PI * r;
      const rate = fakePercent / 100;
      const dash = circleLine * rate;
      const gap = circleLine - dash;
      return `${dash} ${gap}`;
    },
  },
};
</script>

<style scoped>
.bg-lock-gradient {
  background: #050011;
  box-shadow: -10px -10px 30px #201636, 10px 10px 40px #000001;
  filter: drop-shadow(-10px -10px 30px #201636)
    drop-shadow(10px 10px 40px #000001);
}
</style>
