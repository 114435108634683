import { providers } from "ethers";

import { provider } from "../providers/provider";
import connect from "./index";
const connectWalletConnect = async () => {
  try {
    const { state, disconnectWallet } = connect();
    await provider.enable();

    const web3Provider = new providers.Web3Provider(provider);

    const signer = await web3Provider.getSigner();
    const address = await signer.getAddress();

    state.walletType = "WalletConnect";
    state.status = true;
    state.address = address;
    const CID = await provider.request({ method: "eth_chainId" });
    state.chainId = CID.toString(16);
    provider.on("disconnect", (code, reason) => {
      state.status = false;
      state.address = "";
      localStorage.removeItem("userState");
    });
    provider.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        state.address = accounts[0];
        disconnectWallet();
      }
    });
    provider.on("chainChanged", (chainId) => {
      state.chainId = chainId.toString(16);
      disconnectWallet();
    });
  } catch (error) {
    window.location.reload();
  }
};

export default connectWalletConnect;
