import Home from "./../pages/Home.vue";
import Dashboard from "./../pages/Dashboard.vue";
import UnderMaintenance from "./../pages/UnderMaintenance.vue";
import { createRouter, createWebHistory } from "vue-router";
const routeInfos = [
  {
    name: "Dashboard",
    path: "/dashboard",
    props: false,
    component: Dashboard,
  },
  {
    name: "Home",
    path: "/:invite_code?",
    props: true,
    component: Home,
  },
  // {
  //   name: "Maintenance",
  //   path: "/:invite_code?",
  //   props: true,
  //   component: UnderMaintenance,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routeInfos,
});

export default router;
