<template>
    <GradientBox class="mt-39">
        <div class="p-6 text-white" >
            <h4 class="uppercase">Token information</h4>
            <p class="pt-3">You may find further information about the Next Earth token on the link below.</p>
            <div class="text-center mt-24 relative">
                <a href=" https://app.uniswap.org/#/swap?outputCurrency=0x0d0b8488222f7f83b23e365320a4021b12ead608&chain=polygon" target="_blank" class="max-w-full w-full btn btn-primary z-10 flex just items-center">NXTT on Uniswap</a>
            </div>
        </div>
    </GradientBox>
</template>

<script>
import GradientBox from '@/components/base/GradientBox.vue'
export default {
    name: 'DashboardTokenInformation',
    components: {
        GradientBox
    }
}
</script>