<template>
    <GradientBox>
        <div class="p-6 text-white uppercase">
            
            <h3>Impermanent loss<br>compensation</h3>
              
            <div>
                <div class="flex-column md:flex mt-24">
                    <div class="flex-1 uppercase">
                        <h4 class="opacity-50">Total compensation</h4>
                    </div>
                    <div
                        class="flex-none shrink "
                        v-if="true"
                        v-tooltip:top.tooltip="'The amount of the total amount of compensated NXTT'">
                        <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer"/>
                    </div>
                </div>
                
                <div class="flex-column md:flex  mt-2">
                    <div class="flex-1 uppercase">
                        <h2 >NXTT</h2>
                    </div>
                    <div class="flex-none uppercase shrink">
                        <h2 >{{ total_compensation_nxxt }}</h2>
                    </div>
                </div>
            </div>  

            <div>
                <div class="flex-column md:flex mt-24">
                    <div class="flex-1 uppercase"><h4 class="opacity-50">Actual market value</h4></div>
                    <div
                        class="flex-none shrink "
                        v-if="true"
                        v-tooltip:top.tooltip="'The value (in dollar) of your total compensated NXTT'">
                        <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer"/>
                    </div>
                </div>
                <div class="flex-column md:flex  mt-2">
                    <div class="flex-1 uppercase">
                        <h2 >$</h2>
                    </div>
                    <div class="flex-none uppercase shrink">
                        <h2 >{{ total_compensation_usd }}</h2>
                    </div>
                </div>
            </div>

        </div>
    </GradientBox>
</template>

<script>
import GradientBox from '@/components/base/GradientBox.vue'
import IconInfo from '@/components/icons/IconInfo.vue'
import Tooltip from '@/components/base/Tooltip.vue'
export default {
    name: 'DashboardCompensation',
    components: {
        GradientBox,
        IconInfo,
        Tooltip
    },
    props: {
        total_compensation_nxxt: {
            required: true,
            type: [Number, String]
        },
        total_compensation_usd: {
            required: true,
            type: [Number, String]
        }
    }
}
</script>