<template>  
    <svg viewBox="0 0 20 21" class="shrink-0 fill-current"  xmlns="http://www.w3.org/2000/svg">
      <path d="M14.142 0.137695H5.858L0 5.9957V14.2797L5.858 20.1377H14.142L20 14.2797V5.9957L14.142 0.137695ZM18 13.4507L13.313 18.1377H6.687L2 13.4507V6.8237L6.687 2.1377H13.313L18 6.8237V13.4507ZM9.242 11.6377H10.758L11.143 6.2077C11.1526 6.07078 11.1339 5.93334 11.0881 5.80395C11.0423 5.67457 10.9704 5.55599 10.8767 5.45562C10.7831 5.35525 10.6698 5.27522 10.5439 5.22052C10.4181 5.16583 10.2823 5.13763 10.145 5.1377H9.855C9.71774 5.13763 9.58194 5.16583 9.45605 5.22052C9.33016 5.27522 9.21688 5.35525 9.12326 5.45562C9.02964 5.55599 8.95769 5.67457 8.91188 5.80395C8.86607 5.93334 8.84739 6.07078 8.857 6.2077L9.242 11.6377ZM10 12.9877C9.84898 12.9877 9.69944 13.0174 9.55991 13.0752C9.42039 13.133 9.29361 13.2177 9.18683 13.3245C9.08004 13.4313 8.99533 13.5581 8.93754 13.6976C8.87975 13.8371 8.85 13.9867 8.85 14.1377C8.85 14.2887 8.87975 14.4383 8.93754 14.5778C8.99533 14.7173 9.08004 14.8441 9.18683 14.9509C9.29361 15.0577 9.42039 15.1424 9.55991 15.2002C9.69944 15.2579 9.84898 15.2877 10 15.2877C10.305 15.2877 10.5975 15.1665 10.8132 14.9509C11.0288 14.7352 11.15 14.4427 11.15 14.1377C11.15 13.8327 11.0288 13.5402 10.8132 13.3245C10.5975 13.1089 10.305 12.9877 10 12.9877Z"/>
    </svg>

</template>

<script>
export default {
  name: 'IconInfoSquared'
}
</script>