<template>
  <div
    class="container mx-auto sm:px-3 mt-14 lg:mt-20 mb-14 lg:mb-20 px-3 relative"
  >
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="text-white text-center uppercase font-bold">
          <h1 class="text-display-1">Limited OTC Offer</h1>
          <h2 class="text-lg my-4">directly from the token issuer</h2>
          <p class="text-lg">
            Minimum market value correction One time discount, up to 15%
          </p>
          <p class="text-lg">
            Privilege to the best OTC deals in the upcoming launchpad projects
          </p>
        </div>
        <div class="w-full flex justify-center mt-7">
          <ConnectWallet />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import connect from "@/helpers/index";
import ConnectWallet from "@/components/ConnectWallet.vue";
export default {
  name: "InfoPanelSec",
  components: {
    ConnectWallet,
  },
  setup: () => {
    const { state } = connect();
    return {
      state,
    };
  },
};
</script>
