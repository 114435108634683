<template>
    <GradientBox>
        <div class="p-4 grid grid-cols-1 lg:grid-cols-5 gap-12 text-white">
            <div class="col-span-1 md:col-span-2 p-4">
                <h3 class="relative z-30" >Dear {{ name }}!</h3>
                <p>Welcome to the Next Earth token dashboard. <br><br> Here you will find every key information about your NXTT purchase and you can follow how your portfolio develops over time. In case any questions arise, please contact your NXTT personal adviser.</p>
                
                <!-- data 1 -->
                <div>
                    <div class="flex items-center mt-24">
                        <div class="flex-1 uppercase">
                            <h4 class="opacity-50">Date of purchase</h4>
                        </div>
                    </div>
                    <div class="flex items-center mt-2">
                        <div class="flex-1 uppercase">
                            <h2>{{ date_of_purchase }}</h2>
                        </div>
                    </div>
                </div> 
                
                <!-- data 2 -->
                <div>
                    <div class="flex items-center mt-24">
                        <div class="flex-1 uppercase">
                            <h4 class="opacity-50">Amount of NXXT on date of purchase</h4>
                        </div>
                    </div>
                    <div class="flex items-center mt-2">
                        <div class="flex-1 uppercase">
                            <h2 >NXTT</h2>
                        </div>
                        <div class="flex-none uppercase shrink">
                            <h2 >{{ amount_of_nxxt }}</h2>
                        </div>
                    </div>
                </div> 
                
                <!-- data 3 -->
                <div>
                    <div class="flex items-center mt-24">
                        <div class="flex-1 uppercase">
                            <h4 class="opacity-50">Your commitment</h4>
                        </div>
                        <div
                            class="flex-none shrink"
                            v-if="true"
                            v-tooltip:top.tooltip="'The amount of your locked commitment in U.S. dollar'">
                            <IconInfo class="h-7 w-7 text-ne-gray-600 cursor-pointer"/>
                        </div>
                    </div>
                    <div class="flex items-center mt-2">
                        <div class="flex-1 uppercase">
                            <h2 >$</h2>
                        </div>
                        <div class="flex-none uppercase shrink">
                            <h2 >{{ your_commitment }}</h2>
                        </div>
                    </div>
                </div>  
            </div>
            <div class=" col-span-1 h-auto md:col-span-3">
                <GradientBox>
                    <NxttChart/>
                </GradientBox>
            </div>
        </div>
    </GradientBox>
</template>

<script>
import GradientBox from "@/components/base/GradientBox.vue"
import NxttChart from "@/components/dashboard/dashboard-cover/NxttChart.vue"
import Tooltip from "@/components/base/Tooltip.vue"
import IconInfo from "@/components/icons/IconInfo.vue"
export default {
    name: "DashboardCover",
    components: {
        NxttChart,
        Tooltip,
        GradientBox,
        IconInfo
    },
    props: {
        name: {
            required: true,
            type: String
        },
        date_of_purchase: {
            required: true,
            type: [Number, String]
        },
        amount_of_nxxt: {
            required: true,
            type: [Number, String]
        },
        your_commitment: {
            required: true,
            type: [Number, String]
        },
    } 
}
</script>